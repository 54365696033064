import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Reactive Box Jumps 4×4`}</p>
    <p>{`Partial Deadlifts (plates raised onto 45`}{`#`}{` bumpers) 4×4 to 4RM`}</p>
    <p>{`then,`}</p>
    <p>{`21-Deadlifts (135/95, scaled to 35% 1RM)`}</p>
    <p>{`300ft Shuttle Run (in 50ft increments)`}</p>
    <p>{`15-Deadlifts (185/125, scaled to 45% 1RM)`}</p>
    <p>{`300ft Shuttle Run`}</p>
    <p>{`12-Deadlifts (225/155, scaled to 55% 1RM)`}</p>
    <p>{`300ft Shuttle Run`}</p>
    <p>{`9-Deadlifts (275/185, scaled to 65% 1RM)`}</p>
    <p>{`300ft Shuttle Run`}</p>
    <p>{`6-Deadlifts (315/225, scaled to 75% 1RM)`}</p>
    <p>{`300ft Shuttle Run`}</p>
    <p>{`3-Deadlifts (365/255, scaled to 90% 1RM)`}</p>
    <p>{`300ft Shuttle Run`}</p>
    <p>{`10:00 time cap.  Score=time or total reps in time cap.`}</p>
    <p><strong parentName="p">{`*`}{`*`}{`The CrossFit Open starts Thursday, February 21st!  **`}</strong>{`Register at:
Games.CrossFit.com.   We’ll be throwing down each Friday night starting
this Friday the 22nd from 4:30-6:30 so the workouts will be Friday’s wod
each week.  Redo’s will be Saturday’s and Sunday’s after our classes.***`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`You can now schedule a massage with Jennifer May, our newest member
and massage therapist! Jennifer is offering a special for the month of
February. You can get a full hour massage for \\$50!! The massage room is
located on the left as soon as you walk into the gym. She works by
appointment only, so email her to schedule.
 `}<a parentName="em" {...{
            "href": "mailto:j.zenmassagetherapy@gmail.com"
          }}>{`j.zenmassagetherapy@gmail.com`}</a>{`.  Sounds like the perfect Valentines
Day gift…`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      